import Backdrop from '@mui/material/Backdrop';
import { alpha, useTheme } from '@mui/material';
import { FaRegCheckCircle } from 'react-icons/fa';

export const SelectedCardOverlay = ({ isSelected }: { isSelected?: boolean }) => {
  const theme = useTheme();

  if (!isSelected) {
    return null;
  }

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        backgroundColor: theme => alpha(theme.palette.primary.main, 0.2),
        borderRadius: 6,
        cursor: 'pointer',
      }}
      open={isSelected}
    >
      <FaRegCheckCircle color={theme.palette.success.main} size={70} />
    </Backdrop>
  );
};
