import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';
import { TSlideProps } from '@onesource/services';
import { useGetByDevice } from '../../hooks';

export const HeroVideoSlide: FC<TSlideProps> = props => {
  const { sectionTitle, sectionSubtitle, sectionDescription, vimeoVideoId } = props;
  const theme = useTheme();
  const { isDesktop } = useGetByDevice();

  const vimeoUrl = `https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&background=1&loop=1`;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        minHeight: {
          xs: '468px',
          md: '810px',
        },
        '&:before': {
          content: "''",
          position: 'absolute',
          height: '100%',
          zIndex: 1,
          width: {
            xs: '70%',
            md: '50%',
          },
          backgroundImage: `linear-gradient(to left, transparent ,${theme.palette.common.black})`,
        },
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 0,
          position: 'absolute',
          boxShadow: 'none',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            paddingTop: '44.1%',
          }}
        >
          <CardMedia
            component="iframe"
            src={vimeoUrl}
            allow="fullscreen"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              ml: !isDesktop ? { xs: -40, sm: -20, md: -50 } : undefined,
              width: { xs: '1100px', md: '1850px', lg: '100%' },
              height: { xs: '468px', md: '813px', lg: '100%' },
              border: 0,
            }}
          />
        </Box>
      </Card>
      <Container maxWidth="xl">
        <Grid
          sx={{
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: {
              xs: '400px',
              md: '813px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', md: 'column' },
              alignItems: 'center',
              justifyContent: 'space-between',
              minHeight: '350px',
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mt: {
                    xs: 0,
                    md: 9,
                  },
                  mb: { xs: 1.5, md: 3 },
                  color: theme => theme.palette.common.white,
                  '& br': { display: { xs: 'none', md: 'block' } },
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: sectionTitle }} />
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  color: theme => theme.palette.common.white,
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: sectionSubtitle }} />
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mb: 3,
                  color: theme => theme.palette.common.white,
                  lineHeight: 1.6,
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: sectionDescription }} />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};
