import { TDeleteContentFromPlaylistRequestSchema, TDraggableItem } from '@onesource/schemas';
import { FC } from 'react';
import { SortableContents } from '../sortable-contents';
import { DragEndEvent } from '@dnd-kit/core';

interface IProps {
  playlistId?: string;
  contents: TDraggableItem[];
  isDashboard?: boolean;
  enableOrdering?: boolean;
  onMove?: (item: TDraggableItem) => void;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onChangeOrder?: (event: DragEndEvent) => void;
  setSortedItems?: (contents: TDraggableItem[]) => void;
}

export const Reorder: FC<IProps> = props => {
  const { playlistId, contents, onMove, onRemove, enableOrdering, setSortedItems, onChangeOrder, isDashboard } = props;

  return (
    <SortableContents
      items={contents}
      setSortedItems={setSortedItems}
      onRemove={onRemove}
      onMove={onMove}
      enableDrag={enableOrdering}
      onChangeOrder={onChangeOrder}
      playlistId={playlistId ?? ''}
      isDashboard={isDashboard}
    />
  );
};
