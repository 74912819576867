import { useQuery } from '@tanstack/react-query';
import { ENTITY } from './entity';
import axios from '../axios';
import { TError } from '@onesource/schemas';
import { useGetSubscriptionQuery } from '../profile';

export const getUserWatchedContentIDsQueryKey = () => [ENTITY, 'watched-content-ids'];

export type TGetUserWatchedContentIDsQueryResponse = Map<string, true>;

export const useGetUserWatchedContentIDsQuery = () => {
  const { isLoadingProfileInfo } = useGetSubscriptionQuery();

  return useQuery<TGetUserWatchedContentIDsQueryResponse, TError>({
    queryKey: getUserWatchedContentIDsQueryKey(),
    queryFn: async () => {
      const res = await axios.get<string[]>(`${ENTITY}/watched-content-ids`);
      const data = res.data;
      const map = new Map();
      data.forEach(item => {
        map.set(item.toString(), true);
      });

      return map;
    },
    staleTime: Infinity,
    refetchOnMount: false,
    enabled: !isLoadingProfileInfo,
  });
};
