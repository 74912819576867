import { createFileRoute } from '@tanstack/react-router';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';
import { AllClasses } from 'pages/all-classes';

export const Route = createFileRoute('/_protected/classes/all')({
  component: () => (
    <PlaylistMenuProvider>
      <AllClasses />
    </PlaylistMenuProvider>
  ),
});
