import { createFileRoute } from '@tanstack/react-router';
import { Home } from '../pages/home';
import { AuthGuard } from '@onesource/components';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';

export const Route = createFileRoute('/')({
  component: () => (
    <AuthGuard>
      <PlaylistMenuProvider>
        <Home />
      </PlaylistMenuProvider>
    </AuthGuard>
  ),
});
