import IconButton from '@mui/material/IconButton';
import { alpha, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Calender, Clock, NewBadge, VideoIcon } from '@onesource/icons';
import { TContent } from '@onesource/schemas';
import { ellipsis, formatDate } from '@onesource/utils';
import { FC, memo } from 'react';
import { ContentCategoryBadge } from '../../content-category-badge';
import { LockedCardOverlay } from '../../overlays/locked-card';
import { HoverCardOverlay } from './hover-card-overlay';
import { LinkProps } from '@tanstack/react-router';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { SelectedCardOverlay } from '../../overlays/selected-card';

type IProps = {
  content: TContent;
  isLocked: boolean;
  blockClick?: boolean;
  linkProps?: LinkProps;
  hasWatched?: boolean;
  isSelected?: boolean;
  onClick?: (content: TContent) => void;
  sx?: SxProps;
  onClickAddPlaylist?: (anchorEl: HTMLElement, contentId: string) => void;
};

export const Content: FC<IProps> = memo(props => {
  const {
    content,
    sx,
    isLocked,
    blockClick,
    onClickAddPlaylist,
    linkProps,
    onClick,
    isSelected,
    hasWatched = false,
  } = props;
  const { category } = content;
  const theme = useTheme();

  const postMetaStyle = {
    fontSize: '13px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.grey[800],
    p: 1,
    gap: {
      xs: '6px',
      md: '8px',
    },
  };

  return (
    <Box
      {...(onClick && {
        onClick: (e: React.MouseEvent) => {
          if (blockClick) {
            e.stopPropagation();
            e.preventDefault();
          }
          onClick(content);
        },
      })}
      sx={{
        position: 'relative',
        ...sx,
        minWidth: { xs: '250px', sm: '275px', md: '275px', lg: '310px' },
        visibility: 'visible',
      }}
    >
      <Card sx={{ borderRadius: 6, boxShadow: `0px 0px 11px ${theme.palette.common.black}20` }}>
        <Box sx={{ position: 'absolute', top: '15px' }}>
          <ContentCategoryBadge sx={{ ml: 2 }} category={category} />
        </Box>

        {content.isNew && (
          <NewBadge
            style={{
              position: 'absolute',
              fontSize: '70px',
              right: -5,
              top: 0,
            }}
          />
        )}

        <Box
          sx={{
            '&:hover+.hover-card-overlay': {
              opacity: 1,
              visibility: !!onClick ? 'hidden' : 'visible',
            },
          }}
        >
          {content.video.bannerUrl2x && <CardMedia component="img" height="234" image={content.video.bannerUrl2x} />}
        </Box>

        {!isLocked && (
          <Box
            className="hover-card-overlay"
            sx={{
              opacity: 0,
              visibility: 'hidden',
              position: 'absolute',
              transition: 'all 0.25s ease',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: 5.5,
              background: theme => theme.palette.common.white,
              '&:hover': {
                opacity: 1,
                visibility: 'visible',
                zIndex: 1,
              },
            }}
          >
            <HoverCardOverlay linkProps={linkProps} isLocked={isLocked} content={content} blockClick={blockClick} />
          </Box>
        )}

        <CardContent sx={{ p: `0 !important` }}>
          <Box sx={{ background: theme => alpha(theme.palette.primary.light, 0.1) }}>
            <Stack direction="row" spacing={{ xs: 0.3, sm: 0.8, md: 2 }} justifyContent="space-between">
              <Typography sx={postMetaStyle}>
                <Clock fontSize={20} color={theme.palette.primary.main} />
                {content.video.duration} m
              </Typography>

              <Typography sx={postMetaStyle}>
                <Calender fontSize={20} color={theme.palette.primary.main} />
                {formatDate(content.publishedDate)}
              </Typography>

              <Box sx={{ display: 'flex' }}>
                {hasWatched && (
                  <Typography sx={postMetaStyle}>
                    <FaRegCircleCheck fontSize={20} color={theme.palette.primary.main} />
                  </Typography>
                )}

                {typeof onClickAddPlaylist === 'function' && (
                  <IconButton onClick={e => onClickAddPlaylist(e.currentTarget, content.id)} sx={{ borderRadius: 0 }}>
                    <VideoIcon style={{ color: theme.palette.primary.main }} fontSize={20} />
                  </IconButton>
                )}
              </Box>
            </Stack>
          </Box>

          <Box pb={2} px={2} pt={2}>
            <Typography gutterBottom variant="body1" component="div" sx={{ fontSize: '18px', ...ellipsis }}>
              {content.name}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {content.instructor.firstName} {content.instructor.lastName}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <LockedCardOverlay isLocked={isLocked} />
      <SelectedCardOverlay isSelected={isSelected} />
    </Box>
  );
});
