import { useMutation } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

export const useUserCheckinLogMutation = () => {
  return useMutation({
    mutationFn: async (params: { lastCheckinTime: string }) => {
      const res = await axios.post(`${ENTITY}/checkin-log`, params);
      return res.data;
    },
    retry: 3,
    retryDelay: 2000,
  });
};
