import { queryOptions, useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { TBaseEventSchema, TError } from '@onesource/schemas';

type TParams = {
  id: string;
};

export const getEventQueryKey = ({ id }: TParams) => {
  return ['events', id];
};

export const getEventQueryOptions = ({ id }: TParams) => {
  return queryOptions<TBaseEventSchema, TError>({
    queryKey: getEventQueryKey({ id }),
    queryFn: async () => {
      const res = await axios.get<TBaseEventSchema>(`${ENTITY}/${id}`);
      return res.data;
    },
  });
};
export type TGetEventQueryOptionsReturn = ReturnType<typeof getEventQueryOptions>;

export const useGetEventQuery = ({ id }: TParams) => {
  return useQuery(getEventQueryOptions({ id }));
};
export type TGetEventQueryReturn = ReturnType<typeof useGetEventQuery>;
