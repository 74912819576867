import IconButton from '@mui/material/IconButton';
import { alpha, SxProps, TableCellProps, TableProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IPagination, IPaginationForRQ, TError } from '@onesource/schemas';
import { UseQueryResult } from '@tanstack/react-query';
import { FC, MouseEvent, useMemo } from 'react';
import { LoadingError } from '../loading-error';
import { NotFoundCard } from '../not-found';
import { Pagination } from '../pagination';
import { FaInfo } from 'react-icons/fa';

interface TableColumnConfig extends TableCellProps {
  id: string;
  handleInfoPopoverOpen?: (event: MouseEvent<HTMLElement>) => void;
  handleInfoPopoverClose?: () => void;
}

export interface IDataStateHandlerProps<T> {
  queryResult: UseQueryResult<IPaginationForRQ<T>, TError>;
  wrapperSx?: SxProps;
  loadingProps?: {
    loadingWrapperProps?: GridProps;
    loadingItemProps?: GridProps;
  };
  onClickCardHandler?: (content: IPagination<T>) => void;
  onNext?: (pageNo: number) => void;
  onPrev?: (pageNo: number) => void;
  entity: string;
  LoadingComponent: FC;
  IterativeComponent: FC<{ item: T }>;
  gridProps: GridProps;
  isTable?: boolean;
  pagination?: boolean;
  tableConfig?: {
    columns: TableColumnConfig[];
    tableProps?: TableProps;
  };
}

export function DataStateHandler<T extends { id: string }>(props: IDataStateHandlerProps<T>) {
  const {
    wrapperSx,
    loadingProps,
    queryResult,
    onNext,
    onPrev,
    entity,
    LoadingComponent,
    IterativeComponent,
    gridProps,
    isTable = false,
    tableConfig,
    pagination = true,
  } = props;

  const {
    data: { items = [], meta } = {},
    isLoading,
    isError,
    error,
    refetch,
  } = useMemo(() => queryResult, [queryResult]);

  if (isError && error) {
    return (
      <Box sx={wrapperSx}>
        <LoadingError
          sx={{ width: '100%' }}
          entity={entity}
          message={error.message}
          refetch={refetch}
          errorCode={error.code}
        />
      </Box>
    );
  }

  if (isLoading && isTable) {
    return <LoadingComponent />;
  }

  if (isLoading) {
    return (
      <Grid container justifyContent="center" flexDirection="column" spacing={2} {...loadingProps?.loadingWrapperProps}>
        {Array.from({ length: 6 }).map((_x, index) => (
          <Grid key={index} item {...loadingProps?.loadingItemProps}>
            <LoadingComponent />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!items.length) {
    return (
      <Box sx={wrapperSx}>
        <NotFoundCard entity={entity} sx={{ width: '100%' }} />
      </Box>
    );
  }

  if (isTable && !tableConfig) {
    throw Error('table config must be preset if the type is table');
  }

  const columns = tableConfig?.columns || [];
  const tableProps = tableConfig?.tableProps;

  return (
    <Box sx={wrapperSx}>
      <Grid container spacing={4}>
        {(() => {
          if (isTable) {
            return (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table {...tableProps}>
                    <TableHead
                      sx={{
                        background: theme => alpha(theme.palette.primary.main, 0.1),
                        color: theme => theme.palette.common.black,
                      }}
                    >
                      <TableRow>
                        {columns.map(column => {
                          const { handleInfoPopoverOpen, handleInfoPopoverClose, id, children, ...tableCellProps } =
                            column;
                          return (
                            <TableCell key={id} {...tableCellProps}>
                              {children}
                              {id === 'points' && (
                                <IconButton
                                  color="primary"
                                  onMouseEnter={event => handleInfoPopoverOpen && handleInfoPopoverOpen(event)}
                                  onMouseLeave={() => handleInfoPopoverClose && handleInfoPopoverClose()}
                                  sx={{
                                    ml: 2,
                                    background: theme => theme.palette.common.white,
                                  }}
                                >
                                  <FaInfo size="18px" />
                                </IconButton>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody sx={{ background: theme => theme.palette.common.white }}>
                      {items.map(item => (
                        <IterativeComponent item={item} key={item.id} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            );
          }

          return (
            <>
              {items.map(item => (
                <Grid item {...gridProps} key={item.id}>
                  <IterativeComponent item={item} />
                </Grid>
              ))}
            </>
          );
        })()}

        {pagination && (
          <Box display="flex" justifyContent="center" width="100%">
            {meta && onNext && onPrev && <Pagination sx={{ mt: 4 }} meta={meta} onNext={onNext} onPrev={onPrev} />}
          </Box>
        )}
      </Grid>
    </Box>
  );
}
