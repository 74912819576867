import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { alpha, darken } from '@mui/material';
import Box from '@mui/material/Box';
import Chip, { ChipProps } from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { LiveIcon } from '@onesource/icons';
import { theme } from '@onesource/theme';
import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BackButton } from '../components/back-button';
import { CommentsSection } from '../components/comments';
import { ParticipantSection } from '../components/participants';
import { LiveClass } from '../components/sections/live-class';
import { ellipsis } from '../utils/ellipsis';
import { useSocketConnection } from '../utils/socket.connection';
import { EventStatusEnum, PubSubEventsEnum, TEventStatus } from '@onesource/schemas';
import { useEventIdParams } from 'routes/_protected/events/$eventId';
import { formatDateWithTime } from '@onesource/utils';
import { CommentProps } from '@onesource/components';
import { useNavigate } from '@tanstack/react-router';
import { useGetAllProfileQuery } from '@onesource/services';
import { enqueueSnackbar } from 'notistack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const LiveClassroom: FC = () => {
  const navigate = useNavigate();
  const { eventId } = useEventIdParams();
  const { data: userInfo } = useGetAllProfileQuery();
  const [value, setValue] = useState(0);
  const { participants, eventInfo, messages, emmitMessage } = useSocketConnection(eventId);

  const isEventStarted = eventInfo?.status === EventStatusEnum.STARTED;

  const eventStatusChipColor: Record<TEventStatus, ChipProps['color']> = {
    [EventStatusEnum.SCHEDULED]: 'warning',
    [EventStatusEnum.STARTED]: 'success',
    [EventStatusEnum.ENDED]: 'error',
  };

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const handleSendComment = useCallback(
    (comment: string) => {
      emmitMessage({ message: PubSubEventsEnum.SEND_MESSAGE, payload: { message: comment } });
    },
    [emmitMessage],
  );

  const comments = useMemo(() => {
    const data: ({ id: string } & CommentProps)[] = messages.map(item => ({
      id: item.id,
      avatarUrl: item.user?.avatarUrl,
      username: item.user?.username,
      date: formatDateWithTime(item.createdAt),
      comment: item?.message,
    }));
    return data;
  }, [messages]);

  useEffect(() => {
    if (eventInfo?.status !== EventStatusEnum.ENDED) {
      return;
    }

    if (participants.find(item => item.id === `${userInfo?.id}`)) {
      enqueueSnackbar("The event has ended. You'll be redirected to the home page in a few seconds.", {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        onClose: () => {
          navigate({
            to: '/',
          });
        },
      });
    }
  }, [eventInfo?.status, navigate, userInfo]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={5}
          sx={{
            borderRadius: { xs: 0, md: '0px 0px 0px 150px' },
            background: {
              xs: theme.palette.primary.main,
              md: alpha(theme.palette.primary.main, 0.15),
            },
            height: { xs: '75px', md: '140px' },
            px: { xs: 0, md: 5.7 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isEventStarted ? (
              <motion.div
                initial={{ opacity: 0.8, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  repeat: Infinity,
                  duration: 1,
                  ease: 'easeInOut',
                  repeatType: 'reverse',
                }}
              >
                <LiveIcon fontSize={70} color={theme.palette.primary.main} />
              </motion.div>
            ) : (
              <LiveIcon fontSize={70} color={theme.palette.grey[800]} />
            )}
            <Typography
              variant="h3"
              fontSize={40}
              component="h1"
              sx={{
                color: { xs: theme.palette.common.white, md: darken(theme.palette.common.white, 0.799) },
                fontSize: { xs: '30px', md: '40px' },
                ...ellipsis,
              }}
            >
              Live Class
              {eventInfo && (
                <Chip
                  sx={{ ml: 2 }}
                  variant="filled"
                  color={eventStatusChipColor[eventInfo?.status]}
                  label={eventInfo?.status}
                />
              )}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{
            background: { xs: alpha(theme.palette.primary.main, 0.15), md: theme.palette.primary.main },
            borderRadius: { xs: '0px 0px 50px 50px', md: '0px 0px 150px 0px' },
            display: 'flex',
            alignItems: 'center',
            minHeight: '140px',
            px: { xs: 0, md: 5.7 },
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              color: { xs: darken(theme.palette.common.white, 0.799), md: theme.palette.common.white },
              fontWeight: '500',
              fontSize: { xs: '28px', md: '40px' },
              textAlign: { xs: 'center', md: 'left' },
              '& .divider': {
                display: { xs: 'none', md: 'inline' },
              },
            }}
          >
            {eventInfo?.title} <span className="divider">|</span>{' '}
            <span style={{ fontWeight: '400' }}>
              {eventInfo?.instructor.firstName} {eventInfo?.instructor.lastName}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Container maxWidth="xl">
        <Box
          sx={{
            position: 'relative',
            top: -100,
          }}
        >
          <BackButton color="primary" />
        </Box>

        <Grid container spacing={4}>
          <Grid xs={12} item mt={-4}>
            {eventInfo?.status === EventStatusEnum.SCHEDULED && (
              <Alert severity="warning" variant="standard">
                <AlertTitle>Event is not started yet</AlertTitle>
                The event will start at {formatDateWithTime(eventInfo.scheduleDateTime)}
              </Alert>
            )}

            {eventInfo?.status === EventStatusEnum.ENDED && (
              <Alert severity="error" variant="filled">
                <AlertTitle>The Event is finished</AlertTitle>
                The event has ended.
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            {eventInfo && <LiveClass event={eventInfo} />}

            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Live class tabs">
                  <Tab label="About" />
                  <Tab label="Comments" />
                  {/* <Tab label="Comments" /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 3,
                    color: darken(theme.palette.common.white, 0.799),
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: `${eventInfo?.description}` }} />
                </Typography>
                <Box sx={{ width: '100%', mb: 5 }}>
                  {eventInfo?.content?.equipments.map(item => (
                    <Chip
                      key={item.slug}
                      label={item.name}
                      sx={{
                        mr: 1,
                        mb: 1,
                        px: 2,
                        height: '28px',
                        backgroundColor: theme.palette.grey[800],
                        color: 'white',
                      }}
                      size="small"
                    />
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <CommentsSection
                  eventStatus={eventInfo?.status}
                  handleSendComment={handleSendComment}
                  comments={comments}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <ParticipantSection title="Participants" participants={participants} />
            {/* <Ratings title="Ratings" post={content} /> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
