import { IPaginationNew, TContent, TError } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import { useGetSubscriptionQuery } from '../profile';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TFilter = Record<string, any>;

interface IGetContentsParams {
  filters?: TFilter;
  enabled?: boolean;
}

export const getContentsQueryKey = (filters: TFilter = {}) => {
  const serializeFilters = Object.entries(filters || {});
  return [ENTITY, serializeFilters];
};

export const useGetContentsQuery = (param: IGetContentsParams = {}) => {
  const { isLoadingProfileInfo } = useGetSubscriptionQuery();
  const { filters, enabled = true } = param;

  const isDisabled = useMemo(() => [!enabled, isLoadingProfileInfo].some(Boolean), [enabled, isLoadingProfileInfo]);

  const [durationFrom, durationTo] = filters?.duration?.length ? filters?.duration?.split('-') : [];
  const [difficultyFrom, difficultyTo] = filters?.difficulty?.length ? filters?.difficulty.split('-') : [];
  const [intensityFrom, intensityTo] = filters?.intensity?.length ? filters?.intensity.split('-') : [];
  const [nerdinessFrom, nerdinessTo] = filters?.nerdiness?.length ? filters?.nerdiness.split('-') : [];
  const equipments = filters?.equipments?.split(',') || [];
  const instructorIds = filters?.instructorIds?.split(',') || [];

  return useQuery<IPaginationNew<TContent>, TError>({
    queryKey: getContentsQueryKey(filters),
    queryFn: async () => {
      const res = await axios.get<IPaginationNew<TContent>>(ENTITY, {
        params: {
          ...filters,
          instructorIds,
          equipments,
          scrollTop: undefined,
          durationFrom,
          durationTo,
          difficultyFrom,
          difficultyTo,
          intensityFrom,
          intensityTo,
          nerdinessFrom,
          nerdinessTo,
        },
      });

      return res.data;
    },
    enabled: !isDisabled,
  });
};
