import { TError, TPlaylist } from '@onesource/schemas';
import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetSeriesParams {
  seriesId: string;
}

export const getSeriesQueryKey = (seriesId: string) => {
  return [ENTITY, seriesId];
};

export const useGetSeriesQuery = (param: IGetSeriesParams) => {
  const { seriesId } = param;
  return useQuery<TPlaylist, TError>({
    queryKey: getSeriesQueryKey(seriesId),
    queryFn: async () => {
      const res = await axios.get<TPlaylist>(`${ENTITY}/${seriesId}`);
      return res.data;
    },
  });
};
