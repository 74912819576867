import { ButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useGetContentsQuery, useGetSubscriptionQuery, useGetUserWatchedContentsQuery } from '@onesource/services';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { TextFilterNav } from '../text-filter-nav';
import { ContentFilterTypeEnum, TContentFilterType, TContentRequest } from '@onesource/schemas';
import { AddEditPlaylistDialog, Content, Slideshow } from '@onesource/components';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';

export type TMostViewed = {
  sectionTitle: string;
  actionButton?: ButtonProps;
};

export const MostViewed: FC<TMostViewed> = props => {
  const { sectionTitle } = props;
  const [filters, setFilters] = useState<TContentRequest>({
    type: ContentFilterTypeEnum.mostViewedWeekly,
    perPage: 10,
  });

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { data: watchedContents } = useGetUserWatchedContentsQuery();
  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  const mostViewedQueryResult = useGetContentsQuery({ filters });

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const slides = useMemo(() => mostViewedQueryResult.data?.items || [], [mostViewedQueryResult.data]);

  const handleFilter = useCallback((_event: MouseEvent<HTMLElement>, value: string) => {
    setFilters(prev => ({
      ...prev,
      type: value as TContentFilterType,
    }));
  }, []);

  const SliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '10%',
          infinite: true,
        },
      },
    ],
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} sx={{ py: { xs: 3, md: 6 } }}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              mb: 2,
              textAlign: 'center',
            }}
          >
            {sectionTitle}
          </Typography>
          <Box
            sx={{
              mb: 1,
              textAlign: 'center',
              '& a:after': {
                marginLeft: '8px',
                content: '"|"',
                display: 'inline-block',
                fontSize: '16px',
                LineHeight: '20px',
              },
              '& a:last-child:after': {
                display: 'none',
              },
            }}
          >
            <TextFilterNav
              filter={filters.type}
              handleFilter={handleFilter}
              items={[
                { label: 'Last 7 Days', value: ContentFilterTypeEnum.mostViewedWeekly },
                { label: 'Last 30 Days', value: ContentFilterTypeEnum.mostViewedMonthly },
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} pb={5}>
          <Grid
            container
            sx={{
              '& .slick-slider': { width: { xs: '100%', md: 'calc(100% - 70px)' }, margin: 'auto' },
              '& .slick-track': {
                display: 'flex',
                gap: { xs: 2, sm: 4, md: 5 },
                alignItems: 'center',
                py: 1,
              },
            }}
            className="slider"
          >
            <Slideshow
              slides={slides}
              renderSlide={content => (
                <Content
                  hasWatched={watchedContents?.has(content.id)}
                  onClickAddPlaylist={onClickAddPlaylistHandler}
                  isLocked={!isSubscriptionActive}
                  content={content}
                  key={content.id}
                />
              )}
              settings={SliderSettings}
            />
          </Grid>
        </Grid>
      </Grid>

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </Container>
  );
};
