import { styled, useTheme } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';

export const OneSourceSnackbarProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const theme = useTheme();

  const styledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
    },
  }));

  return <SnackbarProvider Components={{ success: styledMaterialDesignContent }}>{children}</SnackbarProvider>;
};
