import type { SVGProps } from 'react';
const SvgSweat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 20"
    fontSize="1rem"
    {...props}
  >
    <g fill="currentColor">
      <path d="M6.678 19.616c-.37-.062-.748-.096-1.11-.19C2.742 18.69.92 16.912.214 14.085c-.517-2.072-.075-4 1.132-5.77C3.16 5.655 4.953 2.976 6.758.307c.08-.117.202-.205.304-.306h.23c.1.11.217.211.3.334.986 1.452 1.965 2.908 2.947 4.363.034.052.071.101.12.169l.842-1.247q.079-.12.161-.238c.196-.274.493-.269.69.02.384.563.763 1.13 1.145 1.697l1.327 1.968a3.38 3.38 0 0 1-.404 4.302.46.46 0 0 0-.145.4c.206 2.76-.82 4.96-3.058 6.579-1.012.733-2.165 1.129-3.414 1.237-.043.003-.084.02-.127.031H6.68zm.49-18.54-.137.195c-1.685 2.497-3.36 5-5.058 7.489C.971 10.228.59 11.823.883 13.575c.595 3.52 4.108 5.896 7.588 5.136 2.59-.566 4.503-2.544 4.983-5.16a6 6 0 0 0 .074-1.517c-.08.032-.145.056-.208.083a3.404 3.404 0 0 1-3.966-.992A3.4 3.4 0 0 1 9.18 7.06c.3-.444.595-.892.903-1.33.086-.124.091-.207.003-.336C9.42 4.42 8.762 3.44 8.102 2.461zm4.838 3.188c-.03.035-.044.048-.055.064-.729 1.079-1.462 2.155-2.183 3.239-.342.513-.463 1.091-.396 1.7a2.643 2.643 0 1 0 4.808-1.783q-.478-.706-.954-1.413z" />
      <path d="M1.584 12.591c.046-1.114.356-2.129.997-3.04.18-.255.397-.32.596-.182.202.14.215.363.035.621-1.336 1.924-1.021 4.476.745 6.008.454.394.972.676 1.543.864.278.092.393.318.29.545-.087.19-.284.268-.52.185-1.828-.644-3.007-1.897-3.496-3.775-.104-.398-.129-.817-.19-1.226M6.626 17.94a.37.37 0 0 1-.377-.391.37.37 0 0 1 .38-.37.39.39 0 0 1 .384.373.39.39 0 0 1-.388.387M9.87 8.73c.038-.15.086-.4.168-.639.08-.237.293-.333.5-.257.206.077.293.286.215.52-.225.679-.085 1.164.465 1.613.195.16.237.375.106.555-.13.178-.357.21-.559.065-.598-.43-.883-1.021-.894-1.857zM12.393 10.654a.39.39 0 0 1-.4.377.385.385 0 0 1-.364-.393.377.377 0 0 1 .38-.372.38.38 0 0 1 .384.388" />
    </g>
  </svg>
);
export default SvgSweat;
