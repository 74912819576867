import { createFileRoute } from '@tanstack/react-router';
import { AuthGuard } from '@onesource/components';
import { SeriesDetails } from '../../pages/series-details';
import { PageHeader } from '../../components/page-header';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';

export const Route = createFileRoute('/series/$seriesId')({
  component: () => (
    <AuthGuard>
      <PageHeader title="Curated Collection" />
      <PlaylistMenuProvider>
        <SeriesDetails />
      </PlaylistMenuProvider>
    </AuthGuard>
  ),
});
