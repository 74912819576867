import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { TOption } from '@onesource/components';
import { ellipsis } from '@onesource/utils';

interface ISidebarCheckboxGroupFilterProps<T extends TOption> {
  onChange: (name: string, value: string) => void;
  items: T[];
  name: string;
  value: string[];
}

export function SidebarFilterCheckboxGroup<T extends TOption>(props: ISidebarCheckboxGroupFilterProps<T>) {
  const { items, onChange, name, value = [] } = props;

  return (
    <FormGroup>
      <Grid container spacing={0}>
        {items.map(option => {
          const { id, label } = option;
          const optionId = String(id);

          return (
            <Grid key={optionId} xs={6} item>
              <Tooltip title={label}>
                <FormControlLabel
                  value={value.includes(optionId)}
                  onChange={() => onChange(name, optionId)}
                  checked={value.includes(optionId)}
                  sx={{
                    maxWidth: '100%',
                    '& .MuiFormControlLabel-label': {
                      ...ellipsis,
                      color: theme => theme.palette.grey[800],
                      fontSize: '13px',
                      fontWeight: 400,
                    },
                  }}
                  control={<Checkbox size="small" />}
                  label={label}
                />
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </FormGroup>
  );
}
