import { useQuery } from '@tanstack/react-query';
import { ENTITY } from './entity';
import axios from '../axios';
import { IPaginationNew, TContent, TError } from '@onesource/schemas';
import { useGetSubscriptionQuery } from '../profile';

export const getUserWatchedContentsQueryKey = () => [ENTITY, 'last-watched-contents'];

export type TGetUserWatchedContentsQueryResponse = Map<string, TContent>;

export const useGetUserWatchedContentsQuery = () => {
  const { isLoadingProfileInfo } = useGetSubscriptionQuery();

  return useQuery<TGetUserWatchedContentsQueryResponse, TError>({
    queryKey: getUserWatchedContentsQueryKey(),
    queryFn: async () => {
      const res = await axios.get<IPaginationNew<TContent>>(`${ENTITY}/watched`);
      const data = res.data;
      const map = new Map();
      data.items.forEach(item => {
        map.set(item.id, item);
      });

      return map;
    },
    staleTime: Infinity,
    refetchOnMount: false,
    enabled: !isLoadingProfileInfo,
  });
};
