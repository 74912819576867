import { createFileRoute } from '@tanstack/react-router';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';
import { ClassesByCategory } from 'pages/classes-by-category';

export const Route = createFileRoute('/_protected/classes/by-category')({
  component: () => (
    <PlaylistMenuProvider>
      <ClassesByCategory />
    </PlaylistMenuProvider>
  ),
});
