import { DevTool } from '@hookform/devtools';
import { RiLoginBoxLine } from 'react-icons/ri';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { LoginSchema, TLogin } from '@onesource/schemas';
import { isLoggedIn, setSession, useLogin } from '@onesource/services';
import { LinkOptions } from '@tanstack/react-router';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from '@onesource/components';

export const Login: FC = () => {
  const { mutateAsync: loginMutation, isPending } = useLogin();
  const isLogin = isLoggedIn();

  const methods = useForm<TLogin>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = handleSubmit(async data => {
    await loginMutation(data, {
      onSuccess: async res => {
        const { data: user, access_token: token } = res.data;
        const homePageLink: LinkOptions['to'] = '/';

        setSession({ user, token });
        window.location.href = homePageLink;
      },
    });
  });

  useEffect(() => {
    if (isLogin) {
      const homePageLink: LinkOptions['to'] = '/';
      window.location.href = homePageLink;
    }
  }, [isLogin]);

  return (
    <Container maxWidth={false} sx={{ px: `0 !important` }}>
      <Grid container height="100dvh">
        <Grid
          md={6}
          sx={{ display: { xs: 'none', md: 'flex' }, background: `url('/login-featured.jpg') center` }}
        ></Grid>

        <Grid xs={12} md={6} display="flex" justifyContent="center">
          <FormProvider
            methods={methods}
            onSubmit={onSubmit}
            sx={{ width: { xs: 300, md: 400 }, display: 'flex', alignItems: 'center' }}
          >
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid xs={12}>
                <RHFTextField name="username" placeholder="Username" />
              </Grid>

              <Grid xs={12}>
                <RHFTextField name="password" placeholder="Password" type="password" />
              </Grid>

              <Grid xs={12}>
                <LoadingButton
                  disabled={!isDirty || !isValid || isPending}
                  autoFocus
                  variant="contained"
                  size="large"
                  type="submit"
                  color="success"
                  startIcon={<RiLoginBoxLine />}
                  onClick={onSubmit}
                  fullWidth
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    color: 'white',
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  Login
                </LoadingButton>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <DevTool control={control} />
    </Container>
  );
};
