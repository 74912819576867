import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { alpha, SxProps } from '@mui/material';
import { PiX } from 'react-icons/pi';
import { FC } from 'react';

export interface DialogTitleProps {
  id?: string;
  children: React.ReactNode;
  onClose?: () => void;
  sx?: SxProps;
}

export const DialogTitle: FC<DialogTitleProps> = props => {
  const { children, onClose, ...other } = props;

  return (
    <MuiDialogTitle sx={{ p: 2, alignItems: 'center' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          color="inherit"
          onClick={onClose}
          sx={{
            position: 'absolute',
            backgroundColor: theme => alpha(theme.palette.common.black, 0.15),
            right: 10,
            top: 10,
          }}
        >
          <PiX size={25} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
