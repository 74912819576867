import { TSlideProps } from '@onesource/services';
import { FC } from 'react';
import { HeroSection } from '../hero-secton';
import { Slideshow } from '../slideshow';

export type THeroSliderProps = {
  slides: TSlideProps[];
};
const SliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dot: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
  swipeToSlide: true,
};

export const HeroSlider: FC<THeroSliderProps> = ({ slides }) => {
  if (slides.length === 1) {
    const slide = slides[0];

    return (
      <HeroSection
        key={`${slide.sectionTitle}-${slide.sectionSubtitle}-${slide.classDate}`}
        sectionTitle={slide.sectionTitle}
        sectionSubtitle={slide.sectionSubtitle}
        sectionDescription={slide.sectionDescription}
        vimeoVideoId={slide.vimeoVideoId}
        bgImg={slide.bgImg}
        type={slide.type}
        status={slide.status}
        actionButton={slide.actionButton} // Optional
        classDate={slide.classDate} // Optional
      />
    );
  }

  return (
    <Slideshow
      slides={slides}
      renderSlide={(slide: TSlideProps) => (
        <HeroSection
          key={`${slide.sectionTitle}-${slide.sectionSubtitle}-${slide.classDate}`}
          sectionTitle={slide.sectionTitle}
          sectionSubtitle={slide.sectionSubtitle}
          sectionDescription={slide.sectionDescription}
          vimeoVideoId={slide.vimeoVideoId}
          bgImg={slide.bgImg}
          type={slide.type}
          status={slide.status}
          actionButton={slide.actionButton} // Optional
          classDate={slide.classDate} // Optional
        />
      )}
      settings={SliderSettings}
    />
  );
};
