import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { capitalize, darken, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CategoryEnumValues, TInstructor } from '@onesource/schemas';
import { TOption, TUseSetSearchParam, useGetByDevice } from '@onesource/components';
import { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { LiaAngleDownSolid } from 'react-icons/lia';
import { RangeSlider } from '../filter-fields/range-for-indicator/index.tsx';
import { SidebarFilterCheckboxGroup } from './checkbox-group/index.tsx';
import { SidebarFilterRadioGroup } from './radio-group/index.tsx';
import { SearchIcon } from '@onesource/icons';
import { useGetInstructorsQuery } from '@onesource/services';

export type TSidebarItem =
  | 'instructor'
  | 'category'
  | 'duration'
  | 'equipments'
  | 'difficulty'
  | 'intensity'
  | 'nerdiness';
// | 'awesomeness';

export type TPostFilterSidebarProps = {
  searchFilterUtils: TUseSetSearchParam;
  hide?: TSidebarItem[];
  isDefaultExpanded?: boolean;
};

export const PostFilterSidebar: FC<TPostFilterSidebarProps> = props => {
  const { searchFilterUtils, hide = [], isDefaultExpanded } = props;
  const { patchFilter, filters, handleResetFilters } = searchFilterUtils;
  const theme = useTheme();
  const { isPortable } = useGetByDevice();
  const { data: instructorsData } = useGetInstructorsQuery();

  const existingEquipments = filters?.equipments?.split(',') || [];
  const existingInstructors = filters?.instructorIds?.split(',') || [];

  const hideInstructor = !hide.includes('instructor');
  const hideCategory = !hide.includes('category');
  const hideDuration = !hide.includes('duration');
  const hideEquipments = !hide.includes('equipments');
  const hideDifficulty = !hide.includes('difficulty');
  const hideIntensity = !hide.includes('intensity');
  const hideNerdiness = !hide.includes('nerdiness');
  // const hideAwesomeness = !hide.includes('awesomeness');

  const instructors: TOption[] =
    instructorsData?.items.map((instructor: TInstructor) => ({
      id: instructor.id,
      label: `${instructor.firstName} ${instructor.lastName}`,
    })) || [];

  const categories = CategoryEnumValues.map(category => ({
    id: category,
    label: capitalize(category.toLowerCase()),
  }));

  const equipments = [
    { label: 'Block', id: 'block' },
    { label: 'Round Band / Resistance Loop', id: 'round-band-resistance-loop' },
    { label: 'Weights', id: 'weights' },
    { label: 'Slider', id: 'slider' },
    { label: 'Resistance Band', id: 'resistance-band' },
    { label: 'Light Weights', id: 'light-weights' },
    { label: 'Chair / Stool', id: 'chair-stool' },
    { label: 'Blanket', id: 'blanket' },
    { label: 'Bolster', id: 'bolster' },
    { label: 'Half Roller', id: 'half-roller' },
    { label: 'Dowel', id: 'dowel' },
    { label: 'Inflatable (Pilates) Ball', id: 'inflatable-ball' },
    { label: 'Pinky / Tennis Ball', id: 'pinky-tennis-ball' },
    { label: 'No Props', id: 'no-props' },
    { label: 'Pillows / Blankets', id: 'pillows-blankets' },
    { label: 'Strap (yoga strap)', id: 'strap-yoga-strap' },
    { label: 'Ankle Weight', id: 'ankle-weight' },
  ];

  const onFilterChange = useCallback((filterName: string, value: string) => {
    const filterToUpdate = { [filterName]: value };
    patchFilter(filterToUpdate);
  }, []);

  const [durationValueMin, durationValueMax] = (filters?.duration || '')?.split('-').map(Number);
  const [difficultyValueMin, difficultyValueMax] = (filters?.difficulty || '')?.split('-').map(Number);
  const [intensityValueMin, intensityValueMax] = (filters?.intensity || '')?.split('-').map(Number);
  const [nerdinessValueMin, nerdinessValueMax] = (filters?.nerdiness || '')?.split('-').map(Number);
  const [query, setQuery] = useState(() => filters?.query ?? '');
  // const [awesomenessValueMin, awesomenessValueMax] = (filters?.awesomeness || '')?.split('-').map(Number);

  const accordionStyle = {
    background: 'none',
    borderRadius: `0 !important`,
    borderBottom: { xs: `1px solid ${theme.palette.grey[300]}`, md: `1px solid ${theme.palette.common.black}` },
    mb: 0,
    mt: 0,
    '&.Mui-expanded': { mb: 0, mt: 0 },
  };

  const accordionSummaryStyle = {
    fontSize: { xs: '16px', md: '17px' },
    color: theme.palette.grey[800],
    p: 0,
    '&.Mui-expanded': {
      mb: 0,
      minHeight: { xs: 30, md: 38 },
    },
    '& .MuiAccordionSummary-content': {
      mt: { xs: 1, md: '14px' },
      mb: { xs: 0.5, md: '10px' },
    },
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setQuery(() => value);

      onFilterChange('query', value);
    },
    [onFilterChange, setQuery],
  );

  const clearFilters = useCallback(() => {
    setQuery('');
    handleResetFilters();
  }, [handleResetFilters]);

  const ClearFilterBtn: FC<{ stackProps?: StackProps }> = memo(({ stackProps }) => (
    <Stack direction="row" justifyContent="end" mb={3} {...stackProps}>
      <Button
        onClick={clearFilters}
        endIcon={
          <Box
            sx={{
              background: theme.palette.primary.main,
              borderRadius: '100%',
              padding: '14px',
              lineHeight: 0,
            }}
          >
            <GrClose
              style={{
                height: '17px',
                width: '17px',
              }}
            />
          </Box>
        }
        sx={{ minWidth: 'auto' }}
      >
        Clear
      </Button>
    </Stack>
  ));

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          mt: -8,
          mb: 1,
          textAlign: 'center',
          fontSize: '13px',
          lineHeight: 2,
          color: theme => theme.palette.grey[500],
          display: isPortable ? 'none' : 'block',
        }}
      >
        Use the filters or search by class title or keywords to find the perfect class!
      </Typography>
      <Box
        sx={{
          background: theme => darken(theme.palette.grey[50], 0.01),
          borderRadius: '30px',
          p: 2.5,
          pb: { xs: 2, md: 2 },
          mb: { xs: -1, md: 5 },
          boxShadow: '10px 10px 10px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <ClearFilterBtn />
        {hideInstructor && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Instructor
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <SidebarFilterCheckboxGroup
                onChange={(name, value) => {
                  if (existingInstructors.includes(value)) {
                    onFilterChange(name, existingInstructors.filter((x: string) => x !== value).join(','));
                    return;
                  }
                  onFilterChange(name, [...existingInstructors, value].join(','));
                }}
                items={instructors}
                name="instructorIds"
                value={existingInstructors.filter((x: string) => x)}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideCategory && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Category
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <SidebarFilterRadioGroup
                value={filters.categoryId}
                onChange={onFilterChange}
                items={categories}
                name="categoryId"
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideDuration && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Duration
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <RangeSlider
                type="duration"
                onChange={value => {
                  onFilterChange('duration', value.join('-'));
                }}
                value={[durationValueMin, durationValueMax]}
                min={0}
                max={100}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideEquipments && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Props
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <SidebarFilterCheckboxGroup
                onChange={(name, value) => {
                  if (existingEquipments.includes(value)) {
                    onFilterChange(name, existingEquipments.filter((x: string) => x !== value).join(','));
                    return;
                  }
                  onFilterChange(name, [...existingEquipments, value].join(','));
                }}
                items={equipments}
                name="equipments"
                value={existingEquipments.filter((x: string) => x)}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideDifficulty && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Difficulty
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('difficulty', value.join('-'));
                }}
                value={[difficultyValueMin, difficultyValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideIntensity && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Intensity
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('intensity', value.join('-'));
                }}
                value={[intensityValueMin, intensityValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {hideNerdiness && (
          <Accordion defaultExpanded={isDefaultExpanded} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} size={24} />}
              sx={accordionSummaryStyle}
            >
              Nerdiness
            </AccordionSummary>
            <AccordionDetails sx={{ p: '0 0 12px 0' }}>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('nerdiness', value.join('-'));
                }}
                value={[nerdinessValueMin, nerdinessValueMax]}
                min={1}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {/* {hideAwesomeness && (
          <Accordion
            defaultExpanded
            sx={{
              background: 'none',
              borderRadius: `0 !important`,
              borderBottom: theme => `1px solid ${theme.palette.common.black}`,
              mb: 0,
              mt: 0,
              '&.Mui-expanded': { mb: 0, mt: 0 },
            }}
          >
            <AccordionSummary
              expandIcon={<LiaAngleDownSolid color={theme.palette.grey[800]} />}
              sx={{
                fontSize: '24px',
                '&.Mui-expanded': { mb: 0 },
                '& .MuiAccordionSummary-content': {
                  mt: 2.5,
                  mb: 2.5,
                },
              }}
            >
              Awesomeness
            </AccordionSummary>
            <AccordionDetails>
              <RangeSlider
                type="intensity"
                onChange={value => {
                  onFilterChange('awesomeness', value.join('-'));
                }}
                value={[awesomenessValueMin, awesomenessValueMax]}
                min={0}
                max={5}
              />
            </AccordionDetails>
          </Accordion>
        )} */}

        <Grid container alignItems="center" mt={3}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Search all videos"
              value={query}
              type="text"
              onChange={handleSearchChange}
              sx={{
                backgroundColor: theme => theme.palette.common.white,
                '&.MuiOutlinedInput-root': {
                  px: 1,
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon
                    style={{
                      height: '45px',
                      width: '45px',
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <ClearFilterBtn stackProps={{ mt: 3, mb: 0 }} />
      </Box>
    </Box>
  );
};
