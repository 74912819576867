import Skeleton from '@mui/material/Skeleton';
import { alpha, darken } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { FC, memo } from 'react';

export const HorizontalPostLoading: FC = memo(() => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Card sx={{ borderRadius: 6, boxShadow: '0px 0px 20px 10px rgba(51, 51, 51, 0.05)' }}>
        <CardContent sx={{ p: '0px !important' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: '140px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Box sx={{ ml: 1, display: { xs: 'none', md: 'block' } }}>
                <Skeleton
                  variant="circular"
                  animation="wave"
                  sx={{
                    ml: 2,
                    width: '50px',
                    height: '50px',
                    borderRadius: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: theme => `0px 4px 5px 0px ${alpha(theme.palette.common.black, 0.2)};`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 4.5,
                }}
              >
                <Box
                  sx={{
                    ml: { xs: 2 },
                    minWidth: { xs: '100px', md: '120px' },
                    height: { xs: '100px', md: '120px' },
                    borderRadius: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: { xs: '100px', md: '120px' }, height: { xs: '100px', md: '120px' } }}
                    animation="wave"
                  />
                </Box>
                <Box sx={{ width: '255px', display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1.25 }}>
                    <Skeleton variant="rectangular" animation="wave" width={35} />
                    <Skeleton variant="rectangular" animation="wave" width={100} />
                  </Box>
                  <Skeleton variant="rectangular" animation="wave" width={175} />
                  <Skeleton variant="rectangular" animation="wave" width={175} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                minWidth: '210px',
                justifyContent: 'flex-end',
                display: { md: 'none', lg: 'flex' },
              }}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  width: '175px',
                  height: '50px',
                  borderRadius: '30px',
                  p: 0,
                  marginRight: '25px',
                  display: 'flex',
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              background: theme => darken(theme.palette.grey[100], 0.01),
              padding: '11px',
              border: theme => `1px solid ${theme.palette.grey[100]}`,
            }}
          >
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center', ml: 1 }}>
              <Box sx={{ width: '100%', mb: 5, display: 'flex', flexDirection: 'row', gap: 1 }}>
                {Array.from({ length: 3 }).map((_, idx) => (
                  <Skeleton
                    key={`equipment-loader-${idx}`}
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      width: '75px',
                      height: '28px',
                      borderRadius: '30px',
                    }}
                  />
                ))}
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                sx={{ maxWidth: '405px', display: { xs: 'none', md: 'flex' }, gap: 1 }}
              >
                {Array.from({ length: 4 }).map((_, idx) => (
                  <Skeleton
                    key={`rating-loader-${idx}`}
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      width: '85px',
                      height: '28px',
                      borderRadius: '30px',
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});
