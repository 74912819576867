import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TLeaderBoardSchema } from '@onesource/schemas';
import { FC, useCallback } from 'react';
import { PointBadge } from './badge';
import { useGetByDevice } from '@onesource/components';
import { theme } from '@onesource/theme';
import { alpha } from '@mui/material';

export type TLeaderBoardRowProps = {
  data: TLeaderBoardSchema;
  onClickAvatar?: (leaderboardData: TLeaderBoardSchema) => void;
};

const columnStyle = {
  borderBottom: '0',
  padding: { xs: '8px', md: '16px' },
  fontSize: { xs: '12px', sm: '13px', md: '14px' },
};

export const LeaderBoardRow: FC<TLeaderBoardRowProps> = props => {
  const { data, onClickAvatar } = props;
  const { index, avatar, name, location, daysActive, workouts, userBadge, points, isSelf } = data;
  const { isMobile } = useGetByDevice();
  const handleAvatarClick = useCallback(() => {
    onClickAvatar?.(data);
  }, []);

  return (
    <TableRow sx={{ backgroundColor: isSelf ? alpha(theme.palette.primary.light, 0.12) : '' }}>
      <TableCell sx={columnStyle}> {index === 0 ? '' : index}</TableCell>
      <TableCell sx={columnStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            onClick={handleAvatarClick}
            component={Button}
            src={avatar}
            sx={{
              p: 0,
              marginRight: 1,
              height: { xs: '40px', md: '55px' },
              width: { xs: '40px', md: '55px' },
              cursor: 'pointer',
              minWidth: 'auto',
            }}
          />
          {name}
        </Box>
      </TableCell>
      <TableCell sx={columnStyle}>{location}</TableCell>
      <TableCell sx={{ ...columnStyle, display: 'flex', justifyContent: 'center' }}>
        <PointBadge width={isMobile ? '40' : '55'} point={userBadge} />
      </TableCell>
      <TableCell sx={{ ...columnStyle, textAlign: 'center' }}>{daysActive}</TableCell>
      <TableCell sx={{ ...columnStyle, textAlign: 'center' }}>{workouts}</TableCell>
      <TableCell sx={{ ...columnStyle, textAlign: 'center' }}>{points}</TableCell>
    </TableRow>
  );
};
