import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Grid from '@mui/material/Grid';
import { TDeleteContentFromPlaylistRequestSchema, TDraggableItem } from '@onesource/schemas';
import { FC } from 'react';
import { DraggableContentCard } from './card/draggable-content-card';

export type TSortableItemProps = {
  item: TDraggableItem;
  playlistId: string;
  enableDrag: boolean;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove?: (item: TDraggableItem) => void;
  isDashboard?: boolean;
};

export const SortableItem: FC<TSortableItemProps> = props => {
  const { item, enableDrag, onRemove, playlistId, onMove, isDashboard } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DraggableContentCard
        onMove={onMove}
        onRemove={onRemove}
        item={item}
        enableDrag={enableDrag}
        playlistId={playlistId}
        isDashboard={isDashboard}
      />
    </Grid>
  );
};
