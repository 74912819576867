import { TContent, TError } from '@onesource/schemas';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';
import {
  TGetUserWatchedContentIDsQueryResponse,
  getUserWatchedContentIDsQueryKey,
} from './use-get-user-watched-content-ids-query';

interface IGetContentParams {
  contentId: string;
}

export const getContentQueryKey = (conentId: string) => {
  return [ENTITY, conentId];
};

export const useGetContentQuery = (param: IGetContentParams) => {
  const qc = useQueryClient();
  const { contentId } = param;

  return useQuery<TContent, TError>({
    queryKey: getContentQueryKey(contentId),
    queryFn: async () => {
      const res = await axios.get<TContent>(`${ENTITY}/${contentId}`);
      const data = res.data;

      if (data) {
        qc.setQueryData<TGetUserWatchedContentIDsQueryResponse>(getUserWatchedContentIDsQueryKey(), oldData => {
          const map = new Map(oldData);

          const hasAlreadyWatched = map.has(contentId);
          if (!hasAlreadyWatched) {
            map.set(contentId, true);
          }
          return map;
        });
      }
      return data;
    },
  });
};
