const grey = {
  '50': '#fafafa',
  '100': '#f5f5f5',
  '200': '#eeeeee',
  '300': '#e0e0e0',
  '400': '#bdbdbd',
  '500': '#9e9e9e',
  '600': '#757575',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121',
};

export const palette = {
  grey,
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  primary: {
    light: '#FF4356',
    main: '#CE3B4D',
    dark: '#902935',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FF4356',
    main: '#CE3B4D',
    dark: '#902935',
    contrastText: '#fff',
  },
  warning: {
    light: '#FFC107',
    main: '#FFA000',
    dark: '#FF6F00',
  },
  info: {
    light: '#64B5F6',
    main: '#2196F3',
    dark: '#1976D2',
  },
  success: {
    light: '#81C784',
    main: '#2BAB9C',
    dark: '#1B5E20',
  },
};
