import { debounce } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type SearchParamFilters = Record<string, string>;

type TOnSearch = (filters: SearchParamFilters) => void;

type TUseSearchParams = {
  initialFilters: SearchParamFilters;
  resetFilters: SearchParamFilters;
  onSearch?: TOnSearch;
};

export type TUseSetSearchParam = {
  patchFilter: (partial: SearchParamFilters) => void;
  removeFilter: (key: string) => void;
  handleResetFilters: () => void;
  filters: SearchParamFilters;
};

export const cleanedFilters = (obj: SearchParamFilters) => {
  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => value));
};

export const useSetSearchParams = (params: TUseSearchParams): TUseSetSearchParam => {
  const { initialFilters, onSearch, resetFilters } = params;
  const navigate = useNavigate();

  // local state
  const [filters, setFilters] = useState(cleanedFilters(initialFilters));

  const debouncedSearch = useMemo(
    () =>
      debounce((filters: SearchParamFilters) => {
        onSearch && onSearch(filters);
      }, 300),
    [onSearch],
  );

  const transformFilterUrl = useCallback(
    (filters: SearchParamFilters) => {
      navigate({ to: '', search: filters, replace: true });
    },
    [navigate],
  );

  const debouncedSetFiltersInUrl = useMemo(
    () =>
      debounce((filters: SearchParamFilters) => {
        transformFilterUrl(filters);
      }, 300),
    [onSearch],
  );

  const patchFilter = useCallback(
    debounce((partial: SearchParamFilters) => {
      setFilters(prev => {
        const cleaned = cleanedFilters({
          ...prev,
          ...partial,
        });
        return cleaned;
      });
    }, 300),
    [filters],
  );

  const handleResetFilters = useCallback(() => {
    const cleaned = cleanedFilters(resetFilters);

    // update url state
    transformFilterUrl(cleaned);

    // update loacal state
    setFilters(cleaned);
  }, [initialFilters, transformFilterUrl]);

  const removeFilter = useCallback(
    (filterName: string) => {
      const newFilters = { ...filters }; // need a copy of the existing filters

      delete newFilters[filterName];

      // update loacal state
      setFilters(newFilters);

      // call the callback for search
      debouncedSearch(newFilters);

      // set filter in the url
      debouncedSetFiltersInUrl(newFilters);
    },
    [filters, transformFilterUrl],
  );

  useEffect(() => {
    const cleaned = cleanedFilters(filters);
    debouncedSearch(cleaned);
    debouncedSetFiltersInUrl(cleaned);
  }, [debouncedSearch, filters]);

  return { patchFilter, removeFilter, handleResetFilters, filters };
};
