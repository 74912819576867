import type { SVGProps } from 'react';
const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 45 45"
    fontSize="1rem"
    {...props}
  >
    <circle cx={22.343} cy={22.343} r={21.343} stroke="#333" strokeWidth={2} />
    <path
      fill="#333"
      d="M18.82 26.937c-1.237 1.214-2.451 2.409-3.668 3.601q-.971.948-1.945 1.893c-.669.65-1.341.748-1.852.27-.549-.513-.462-1.13.265-1.838q2.61-2.537 5.22-5.073c.147-.142.285-.29.422-.43-3.004-4.677-.705-9.305 2.325-11.147 3.393-2.063 7.835-1.45 10.445 1.496 2.656 2.996 2.62 7.378-.086 10.345-2.54 2.784-7.193 3.738-11.127.883m5.102-12.138c-3.452.008-6.202 2.678-6.208 6.024-.006 3.36 2.786 6.066 6.249 6.055 3.418-.01 6.184-2.725 6.18-6.065-.003-3.332-2.786-6.023-6.221-6.014"
    />
  </svg>
);
export default SvgSearchIcon;
