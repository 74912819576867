import { TOnSaveParams } from '@onesource/components';
import {
  TDeleteContentFromPlaylistRequestSchema,
  TMovePlaylistContentRequestSchema,
  TPlaylist,
  TDraggableItem,
} from '@onesource/schemas';
import {
  useAddContentToPlaylistMutation,
  useCreatePlaylistMutation,
  useMovePlaylistContentMutation,
  useRemoveContentFromPlaylistMutation,
} from '@onesource/services';
import { useCallback, useState } from 'react';

export const usePlaylistAction = (currentPlaylist?: TPlaylist) => {
  const [movePlaylistItemContext, setMovePlaylistItemContext] = useState<TDraggableItem | null>(null);
  const [isCreatePlaylistDialogOpen, setIsCreateNewPlaylistDialogOpen] = useState(false);
  const [contextClassId, setContextClassId] = useState<string | undefined>();

  const { mutateAsync: createPlaylistMutation, isPending: isCreatePending } = useCreatePlaylistMutation();
  const { mutateAsync: addToPlaylistMutation, isPending: isAddToPlaylistPending } = useAddContentToPlaylistMutation();
  const { mutateAsync: movePlaylistItem, isPending: isMovePending } = useMovePlaylistContentMutation();
  const { mutateAsync: removeContent, isPending: isRemovePending } = useRemoveContentFromPlaylistMutation();

  const onCloseMovePlaylistItemDialog = useCallback(() => {
    setMovePlaylistItemContext(null);
  }, []);

  const movePlaylistItemHandler = useCallback(async (params: TMovePlaylistContentRequestSchema) => {
    await movePlaylistItem(params, {
      onSuccess: onCloseMovePlaylistItemDialog,
    });
  }, []);

  const openMovePlaylistItemDialogHandler = useCallback((playlistItem: TDraggableItem) => {
    setMovePlaylistItemContext(playlistItem);
  }, []);

  const handleOnAddToPlaylist = useCallback(async (playlistId: string, contentId: string) => {
    await addToPlaylistMutation({ playlistId, contentId });
  }, []);

  const onOpenCreatePlaylistDialog = useCallback((classId?: string) => {
    setIsCreateNewPlaylistDialogOpen(true);
    setContextClassId(classId);
  }, []);

  const onClosePlaylistDialog = useCallback(() => {
    setIsCreateNewPlaylistDialogOpen(false);
    setContextClassId(undefined);
  }, []);

  const removePlaylistItem = useCallback(
    async (params: TDeleteContentFromPlaylistRequestSchema, cb?: (playlistItemId: number) => void) => {
      if (!currentPlaylist) {
        throw new Error('Playlist not found');
      }

      await removeContent(params, {
        onSuccess: () => {
          cb?.(params.itemId);
        },
      });
    },
    [removeContent, currentPlaylist],
  );

  const createPlayListHandler = useCallback(
    async (params: TOnSaveParams) => {
      const { name, contentId } = params;
      await createPlaylistMutation(
        { name, contentId },
        {
          onSuccess: () => {
            onClosePlaylistDialog();
          },
        },
      );
    },
    [onClosePlaylistDialog, createPlaylistMutation],
  );

  return {
    isCreatePending,
    isMovePending,
    isRemovePending,
    isAddToPlaylistPending,
    contextClassId,

    isCreatePlaylistDialogOpen,

    onCloseMovePlaylistItemDialog,
    movePlaylistItemHandler,
    movePlaylistItemContext,
    removePlaylistItem,

    onAddToPlaylist: handleOnAddToPlaylist,
    createPlaylist: createPlayListHandler,

    onClickCreate: onOpenCreatePlaylistDialog,
    onClosePlaylistDialog,
    openMovePlaylistItemDialogHandler,
  };
};
