import { SxProps } from '@mui/material/styles';
import {
  ECompleteContentAction,
  IPaginationNew,
  TCompleteContentAction,
  TContent,
  TPlaylist,
} from '@onesource/schemas';
import Player from '@vimeo/player';
import { FC, useEffect, useMemo, useRef } from 'react';
import { VideoPlayer } from '../video-player';

export type TVimeoPlayerProps = {
  content: TContent;
  onVideoEnd?: (videoId: string) => void;
  onMarkComplete?: (videoId: string, action: TCompleteContentAction) => void;
  sx?: SxProps;
  playlists?: IPaginationNew<TPlaylist>;
  isPendingUpdatePlaylist?: boolean;
  onClickCreate?: () => void;
};

export const VimeoPlayer: FC<TVimeoPlayerProps> = props => {
  const { content, onVideoEnd, onMarkComplete } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const sentActionRef = useRef<TCompleteContentAction | null>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const player = new Player(iframeRef.current);

      player.on('timeupdate', async () => {
        const totalTime = await player.getDuration();

        const currentTime = await player.getCurrentTime();
        const min1 = 60;
        const flooredCurrentTime = Math.floor(currentTime);

        if (flooredCurrentTime === 2 * min1 && sentActionRef.current === null) {
          sentActionRef.current = ECompleteContentAction['2min_watch'];
          onMarkComplete?.(content.id, ECompleteContentAction['2min_watch']);
        }

        if (flooredCurrentTime === 5 * min1 && sentActionRef.current === ECompleteContentAction['2min_watch']) {
          sentActionRef.current = ECompleteContentAction['5min_watch'];
          onMarkComplete?.(content.id, ECompleteContentAction['5min_watch']);
        }

        if (currentTime === totalTime - min1 && sentActionRef.current === ECompleteContentAction['5min_watch']) {
          sentActionRef.current = ECompleteContentAction['1min_before_finish'];
          onMarkComplete?.(content.id, ECompleteContentAction['1min_before_finish']);
        }
      });

      player.on('ended', () => {
        onVideoEnd?.(content.id);
      });

      return () => {
        player.off('ended');
      };
    }
  }, [onMarkComplete]);

  const videoPlayerProps = useMemo(() => ({ ...props, iframeRef }), [props, iframeRef]);

  return <VideoPlayer {...videoPlayerProps} />;
};
