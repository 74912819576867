import { EventStatusEnum } from '@onesource/schemas';
import { getEventQueryOptions } from '@onesource/services';
import { QueryClient } from '@tanstack/react-query';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { LiveClassroom } from 'pages/live-classroom';
import { NotFoundPage } from 'pages/not-found';
import { object, string } from 'zod';

const RouteParamSchema = object({
  eventId: string().uuid().min(1, 'eventId is required'),
});

export const Route = createFileRoute('/_protected/events/$eventId')({
  loader: async ({ params }) => {
    const qc = new QueryClient();
    const { eventId } = params;
    const data = await qc.ensureQueryData(getEventQueryOptions({ id: eventId }));

    if (data.status === EventStatusEnum.ENDED) {
      throw notFound({ routeId: '/_protected/events/$eventId' });
    }
  },
  component: LiveClassroom,
  notFoundComponent: NotFoundPage,
  parseParams: params => {
    return RouteParamSchema.parse(params);
  },
});

export const useEventIdSearch = Route.useSearch;
export const useEventIdParams = Route.useParams;
