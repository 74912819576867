import { createFileRoute } from '@tanstack/react-router';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';
import { ClassesByTeachers } from 'pages/classes-by-teachers';

export const Route = createFileRoute('/_protected/classes/by-teachers')({
  component: () => (
    <PlaylistMenuProvider>
      <ClassesByTeachers />
    </PlaylistMenuProvider>
  ),
});
