import { alpha, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ContentFilterTypeEnum } from '@onesource/schemas';
import { useGetContentsQuery, useGetSubscriptionQuery, useGetUserWatchedContentsQuery } from '@onesource/services';
import { theme } from '@onesource/theme';
import { Link, RoutePaths } from '@tanstack/react-router';
import { FC, useCallback } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { AddEditPlaylistDialog, Content, Slideshow } from '@onesource/components';
import { RegisteredRouter } from '@tanstack/react-router';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';

export type TRecentlyAddedProps = {
  sx?: SxProps;
  title: string;
  buttonLabel: string;
  buttonLink: RoutePaths<RegisteredRouter['routeTree']>;
};

export const RecentlyAdded: FC<TRecentlyAddedProps> = props => {
  const { sx, buttonLabel, buttonLink, title } = props;

  const { data: watchedContents } = useGetUserWatchedContentsQuery();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, onClosePlaylistDialog],
  );

  const { data: recentlyAdded } = useGetContentsQuery({
    filters: {
      type: ContentFilterTypeEnum.recentlyAdded,
      perPage: 4,
    },
  });

  const { isSubscriptionActive } = useGetSubscriptionQuery();

  const SliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 0,
    arrows: false,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '10%',
          infinite: true,
        },
      },
    ],
  };

  return (
    <Box sx={{ background: alpha(theme.palette.primary.light, 0.12), ...sx }}>
      <Container maxWidth="xl" sx={{ py: { xs: 5, md: 10 } }}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                mt: { xs: 1, md: 2 },
                '& .slick-slider': { width: '100%' },
                '& .slick-track': { display: 'flex', py: 1, gap: { xs: 2, sm: 4, md: 5 }, alignItems: 'center' },
              }}
              className="slider"
            >
              <Slideshow
                slides={recentlyAdded?.items || []}
                renderSlide={content => (
                  <Content
                    hasWatched={watchedContents?.has(content.id)}
                    onClickAddPlaylist={onClickAddPlaylistHandler}
                    isLocked={!isSubscriptionActive}
                    content={content}
                    key={content.id}
                  />
                )}
                settings={SliderSettings}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: { xs: 2, md: 3 } }}>
            <Link to={buttonLink}>
              <Button
                endIcon={
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      borderRadius: '100%',
                      padding: '14px',
                      lineHeight: 0,
                    }}
                  >
                    <SlArrowRight
                      style={{
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  </Box>
                }
                sx={{ paddingLeft: '20px' }}
              >
                {buttonLabel}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </Box>
  );
};
