import { YouMayLikeRequestFilterSchema } from '@onesource/schemas';
import { createFileRoute } from '@tanstack/react-router';
import { PlaylistMenuProvider } from 'components/add-to-playlist/provider';
import { ClassDetails } from 'pages/class-details';
import { object, string } from 'zod';

const RouteParams = object({
  classId: string().min(1, 'classId is required'),
});

const RouteSearchParams = YouMayLikeRequestFilterSchema.omit({ contentId: true });

export const Route = createFileRoute('/_protected/classes/details/$classId')({
  component: () => (
    <PlaylistMenuProvider>
      <ClassDetails />
    </PlaylistMenuProvider>
  ),
  parseParams: params => RouteParams.parse(params),
  validateSearch: params => RouteSearchParams.parse(params),
});

export const useClassDetailsPageParams = Route.useParams;
export const useClassDetailsPageSearchParams = Route.useSearch;
